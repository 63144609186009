<template>
  <section id="store" data-aos="fade-up" class="full-screen-section flex-container">
      <div class="grid align-center">
          <div class="col flex-center" data-aos="fade-right" data-aos-delay="200">
            <img src="@/assets/apple.png" alt="Logo de Apple Store" class="store-logo"  style="width: 100px;" />
          </div>

          <div class="col flex-center" data-aos="fade-right" data-aos-delay="400">
            <div>  <!-- Contenedor adicional para el texto, para mantener el texto alineado a la izquierda -->
              <h2>Aplicaciones en Apple Store y Android</h2>
              <p>Nuestras aplicaciones están disponibles tanto en la Apple Store como en Android, brindando soluciones accesibles y confiables a una amplia gama de usuarios.</p>
            </div>
          </div>

          <div class="col flex-center" data-aos="fade-right" data-aos-delay="600">
            <img src="@/assets/googleplay.png" alt="Logo de Google Play" class="store-logo" style="width: 300px;" />
          </div>

    </div>
    <br />

    <div class="grid">
      <div class="col-12 md-6 lg-4">
        <h3>Aplicacion Radio Institucional:</h3>
        <p>Con nuestra aplicación Radio Institucional, puedes crear un canal de comunicación enriquecido en tu institución. Accede a una demo y descubre cómo nuestra aplicación puede mejorar la comunicación y la colaboración.</p>
        
        <button>Demo Radio</button>
      </div>
      <div class="col-12 md-6 lg-4">
        <h3>App TV Institucional Live Streaming</h3>
        <p>Ofrecemos una aplicación robusta para transmisiones en vivo, permitiendo a las instituciones compartir contenido en tiempo real con su audiencia. Accede a una demo y explora las posibilidades de nuestro servicio de streaming en vivo.</p>

        <button>Demo Tv</button>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'ProductsComponent',
};
</script>


<style scoped>

  .full-screen-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.flex-container {
  display: flex;
  flex-direction: column; /* Orienta los elementos en una columna */
  justify-content: center; /* Centra los elementos a lo largo del eje vertical */
  align-items: center; /* Centra los elementos a lo largo del eje horizontal */
}



.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Ajusta la altura al 100% de la altura de la ventana */
}

.align-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-logo {
  max-width: 100%; /* Asegura que los logos se redimensionen correctamente */
  height: auto; /* Mantiene la relación de aspecto de los logos */
}
</style>


