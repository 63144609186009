<template>
  <footer class="footer">
    <p>&copy; 2023 Soluciones Naturales</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
.footer {
  /* Estilos para el footer */
}
</style>
