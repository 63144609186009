<template>
  <section id="products" data-aos="fade-up" class="full-screen-section">
    <h1 data-aos="fade-down" data-aos-delay="100">Nuestros Productos</h1>
    <div class="grid">
      <!-- Producto 1 -->
      <div class="col-12 md-6 lg-6" data-aos="fade-right" data-aos-delay="200">
        <video autoplay loop muted class="product-video">
          <source src="@/assets/truck.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <h3>{{ product1.title }}</h3>
        <h4>{{ product1.subtitle }}</h4>
        <p>{{ product1.description }}</p>
        <button>Acceso Demo</button>
      </div>
      <!-- Producto 2 -->
      <div class="col-12 md-6 lg-6" data-aos="fade-right" data-aos-delay="200" style="widows: 90%;">
        <video autoplay loop muted class="product-video">
          <source src="@/assets/buy.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <h3>{{ product2.title }}</h3>
        <h4>{{ product2.subtitle }}</h4>
        <p>{{ product2.description }}</p>
        <button>Librería</button>
        <br /> <br />
        <button>Tienda Saludable</button>
      </div>
    </div>
    <br />
  </section>
</template>

<script>
export default {
  name: 'ProductsComponent',
  data() {
    return {
      product1: {
        title: 'Sistema WEB/MÓVIL Transporte',
        subtitle: 'Control Documental - Viajes - Conductores - Libreta Conduccion - Neumaticos',
        description: 'Nuestra plataforma de gestión de transporte, accesible tanto desde la web como desde dispositivos móviles, ofrece una solución integral para la administración logística y operativa del transporte. Este sistema está diseñado para facilitar el control documental, la gestión de viajes, conductores, libretas de conducción y neumáticos. Te invitamos a acceder a una demostración en línea para descubrir cómo podemos personalizar la implementación de esta plataforma y ayudar a optimizar las operaciones de transporte de tu empresa, adaptándonos a tus necesidades específicas.'
      },
      product2: {
        title: 'Sistema WEB/MÓVIL E-commerce',
        subtitle: 'Ventas - Inventario - Paypal - Compras - Punto Venta - Envios',
        description: 'Descubre nuestra solución de comercio electrónico con gestión de inventario y procesamiento de pagos mediante PayPal. Accede a nuestras demos de librería y tienda saludable para explorar las características y capacidades de nuestro sistema.'
      }
    }
  }
};
</script>

<style scoped>
/* Tus estilos existentes... */
.product-video {
  width: 60%; /* Ajusta la anchura del video al 100% de su contenedor */
  border: 1px solid #ccc; /* Borde opcional alrededor del video */
}
</style>
