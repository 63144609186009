<template>

  <section id="services-intro" class="intro-banner">
    <div class="overlay">
      <h2>Nuestros Servicios</h2>

    </div>
  </section>

  <section id="services" data-aos="fade-up">
    <div class="grid">
       <div v-for="(service, index) in services" :key="service.title" class="col-12 service-card" data-aos="fade-right" :style="{ 'min-height': '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }">
        <div class="grid" style="max-width: 1200px; width: 100%;">
          <div v-if="index % 2 === 0" class="grid">
            <!-- Para índices pares -->
            <div :class="isMobile == false ? 'col-7' : 'col-12 md-5'">
              <h3>{{ service.title }}</h3>
              <ul class="mt-2">
                <li>{{ service.description }}</li>
              </ul>
            </div>
            <div  :class="isMobile == false  ? 'col-4' : 'col-12 md-7'">
              <div v-if="service.mediaType === 'image'">
                <img :src="service.media" alt="" class="img-fluid shadow-card-nohover">
              </div>
              <div v-else-if="service.mediaType === 'video'">
                <video :src="service.media" autoplay loop muted class="img-fluid shadow-card-nohover" style="max-width: 100%;"></video>
              </div>
            </div>
          </div>
          <div v-else class="grid">
            <!-- Para índices impares -->
            <div :class="isMobile == false ? 'col-4' : 'col-12 md-7'">
              <div v-if="service.mediaType === 'image'">
                <img :src="service.media" alt="" class="img-fluid shadow-card-nohover" style="max-width: 100% !important;">
              </div>
              <div v-else-if="service.mediaType === 'video'">
                <video :src="service.media" autoplay loop muted class="img-fluid shadow-card-nohover" style="max-width: 100%;"></video>
              </div>
            </div>
            <div  :class="isMobile == false ? 'col-7' : 'col-12 md-5'">
              <h3>{{ service.title }}</h3>
              <ul class="mt-2">
                <li>{{ service.description }}</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
         <div v-if="index < services.length - 1" class="col-12" style="border-bottom: 1px solid #ddd; margin-bottom: 20px;"></div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'ServicesComponent',
  computed: {
    isMobile() {
      return window.innerWidth <= 500;  // Asume que es móvil si el ancho de la ventana es <= 768px
    }
  },
  data() {
    return {
      // Lista de servicios
      services: [
        {
    title: 'Desarrollo de Software Personalizado',
    description: 'Brindamos soluciones de software a medida, diseñadas específicamente para satisfacer las necesidades únicas de tu negocio. Nuestro enfoque centrado en el cliente nos permite crear sistemas robustos y escalables que impulsan la eficiencia operativa y ofrecen una ventaja competitiva.',
    mediaType: 'video',
          media: require('@/assets/prog.mp4') // Modificado aquí
},
{
    title: 'Transición Eficaz a la Nube',
    description: 'Facilitamos la migración de tus operaciones y datos a plataformas en la nube, asegurando una transición suave y segura. Nuestro equipo de expertos evalúa tus necesidades y recomienda la mejor estrategia de migración, permitiéndote aprovechar las ventajas de la nube para mejorar la agilidad y reducir los costos operativos.',
    mediaType: 'image',
          media: require('@/assets/migration2.png')  // Modificado aquí
},
{
    title: 'Creación de Aplicaciones Móviles Innovadoras',
    description: 'Desarrollamos aplicaciones móviles vanguardistas que proporcionan una experiencia de usuario excepcional. Nuestras soluciones móviles son intuitivas, confiables y diseñadas para impulsar el engagement y satisfacer las demandas de un mercado en constante evolución.',
    mediaType: 'video',
          media: require('@/assets/video2.mp4')  // Modificado aquí
},

        {
          title: 'Seguridad',
          description: 'Tras un meticuloso análisis de la situación actual, hemos diseñado un plan integral destinado a fortalecer tu seguridad, garantizando así una protección robusta y una tranquilidad duradera. Este plan involucra una serie de medidas estratégicas y tecnológicas avanzadas, adaptadas específicamente a tus necesidades y circunstancias',
         mediaType: 'image',
          media: require('@/assets/migration.png') // Modificado aquí
        },
        {
          title: 'Pruebas de estres',
          description: 'Detectamos las áreas vulnerables mediante rigurosas evaluaciones, para luego elaborar un plan de optimización a medida, fortaleciendo así la resiliencia y eficacia de tus sistemas.',
          mediaType: 'image',
          media: require('@/assets/amedia.jpeg') // Modificado aquí
        },
        {
    title: 'Evaluación y Certificación de Gestión',
    description: 'Utilizando las mejores prácticas de Scrum, llevamos a cabo una evaluación meticulosa de la gestión de proyectos y operaciones, comparando los objetivos planificados con los resultados reales obtenidos. A través de un detallado flujo de trabajo, que culmina en la certificación con Git Flow, aseguramos que cada etapa del proyecto esté alineada con los estándares de calidad y eficiencia deseados. Este servicio permite una revisión exhaustiva del rendimiento, facilitando la identificación de desviaciones y áreas de mejora, y promoviendo la optimización continua de los procesos.',
    mediaType: 'video',
          media: require('@/assets/loco.mp4') // Modificado aquí
}
        // ... otros servicios
      ]
    };
  }
};
</script>
<style scoped>
.separator {
  width: 100%;
  height: 1px;  /* o la altura que prefieras */
  background-color: #e0e0e0;  /* o el color que prefieras */
  margin: 20px 0;  /* margen arriba y abajo para espacio adicional */
}

.dashed li {
  list-style-type: '- ';
}

.card-pattern {
  height: 20px;
  background: linear-gradient(45deg, #606dbc 25%, transparent 25%) 0 0,
              linear-gradient(45deg, transparent 75%, #606dbc 75%) 0 0,
              linear-gradient(45deg, transparent 75%, #606dbc 75%) 50px 50px,
              linear-gradient(45deg, #606dbc 25%, transparent 25%) 50px 50px;
  background-size: 20px 20px;
}


  .card-pattern {
  height: 20px; /* o la altura que desees */
  background: repeating-linear-gradient(
    45deg,
    black,
    black 1px,
    #01bac3 10px,
    #01bac3 2px
  );
}

#services {
  padding: 20px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: box-shadow 0.3s;
  display: flex;  /* Modificación: Añadido flex para gestionar el diseño interior */
  flex-direction: column;  /* Modificación: Dirección de los elementos flex */
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.card-image {
  margin-top: 10px;  /* Modificación: Añadido margen superior */
  text-align: center;  /* Modificación: Alineación central para la imagen en su contenedor */
}

.card-image img {
  width: 150px;
  height: auto;
  display: inline-block;  /* Modificación: Cambiado a inline-block */
}

.card-content {
  padding: 20px;
  text-align: center;
  flex-grow: 1;  /* Modificación: Añadido flex-grow para ocupar el espacio disponible */
}

.media-content video {
  width: 100%; /* Asegura que el video tenga un ancho del 100% */
  height: auto; /* Ajusta la altura automáticamente para mantener la relación de aspecto */
  object-fit: cover; /* Asegura que el video cubra el área de su contenedor */
}

/* Si deseas que el video tenga una altura específica, puedes especificarla: */
@media (min-width: 768px) {
  .media-content video {
    height: 300px; /* O la altura que desees */
  }
}
</style>