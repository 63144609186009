<template>
  <span>
  <div class="cv-mat-container" ref="pdfContent">
    <h1>{{ CVData.name }} <button class="button pdf-button"  @click="downloadPDF">Descargar PDF</button></h1>
    <div class="contact-info">
      <div><strong>Email:</strong> {{ CVData.email }}</div>
      <div><strong>Idiomas:</strong> {{ CVData.languages }}</div>
      <div><strong>Dirección:</strong> {{ CVData.address }}</div>
    </div>
    <hr>
    <h2>Experiencia</h2>
    <div class="card-container">
      <div v-for="(exp, index) in CVData.experience" :key="index" class="card-cus">
        <div class="card-header">{{ exp.title }}</div>
        <div>{{ exp.company }}</div>
        <div>{{ exp.description }}</div>
        <div>{{ exp.duration }}</div>
      </div>
    </div>
    <hr>
    <h2>Habilidades y Competencias</h2>
    <div class="skills">
      <div>
        <strong>Lenguajes de programación:</strong> {{ CVData.programmingLanguages }}
      </div>
      <div>
        <strong>Bases de datos:</strong> {{ CVData.databases }}
      </div>
      <div>
        <strong>Versionado:</strong> {{ CVData.versionControl }}
      </div>
      <div>
        <strong>Configuración de instancias:</strong> {{ CVData.cloudConfiguration }}
      </div>
      <div>
        <strong>Otros software:</strong> {{ CVData.otherSoftware }}
      </div>
      <div>
        <strong>Habilidades adicionales:</strong> {{ CVData.additionalSkills }}
      </div>
    </div>
    <hr>
    <h2>Educación</h2>
    <div class="education">
      <div><strong>{{ CVData.degree }}</strong></div>
      <div>{{ CVData.university }}</div>
      <div>{{ CVData.graduationDate }}</div>
    </div>
    <hr>
    <h2>Otros</h2>
    <div class="other">
      <div><strong>Pasa tiempos:</strong> {{ CVData.hobbies }}</div>
    </div>
     <div class="buttons-container">
      <button class="button pdf-button"  @click="downloadPDF">Descargar PDF</button>
    </div>
  </div>

    <!--p class="text-black">Certificado Título</p>
    <Image src="https://snat.cl/recursos-kosexa/Certificado-Titulo-Matias-Alemparte.jpg" alt="Image" width="200" preview /-->

  
  </span>
</template>

<script>
import CVData from '@/data/cv_mat.js'
import html2pdf from 'html2pdf.js';
//import Image from 'primevue/image';


export default {
  name: 'CVMat',
  components: {
              },
  methods: {

    downloadPDF() {
    const element = this.$refs.pdfContent;
    const options = {
      margin: 10,
      filename: 'mi-cv.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(options).save();
  }
  },
  props: ["device"],
  data() {
    return {
      CVData
    }
  }
}
</script>

<style scoped>
  /* Estilos aquí */
</style>


  <style scope>
/* Estilos de fuente */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Contenedor principal */
.cv-mat-container {
  background: #ffffff; /* Fondo blanco */
  color: #333333; /* Texto oscuro para mejor contraste */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Botones */
.button {
  background: #f5cd79;
  color: #333;
  /* Resto de los estilos permanecen igual */
}

.button:hover {
  background: #ebebeb;
  color: #333;
}

/* Contenedor de tarjetas */
.card-container {
  /* Resto de los estilos permanecen igual */
}

/* Tarjeta */
.card-cus {
  background: #f9f9f9; /* Fondo ligeramente gris para las tarjetas */
  color: #333333; /* Texto oscuro */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Sombra más suave */
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  max-width: 700px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Encabezado de tarjeta */
.card-header {
  background: #e6e6e6; /* Fondo ligeramente gris para los encabezados */
  color: #333;
  /* Resto de los estilos permanecen igual */
}

/* Información de contacto y secciones */
.contact-info div, .experience div, .education div, .other div, .skills div {
  color: #333333; /* Texto oscuro para mejor contraste */
  margin-bottom: 0.5rem;
}


/* Experiencia laboral */
.experience div {
  margin-bottom: 0.5rem;
  text-align: center;
  color: #ccc;
}

/* Educación */
.education div {
  margin-bottom: 0.5rem;
  text-align: center;
  color: #ccc;
}

/* Otros */
.other div {
  margin-bottom: 0.5rem;
  text-align: center;
  color: #ccc;
}
/* Clase para el contenedor de los botones */
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

/* Estilos del botón "Certificado Título" */
.certificate-button {
  background-color: var(--secondary-color);
  border-radius: 25px;
  color: var(--light-color);
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  margin: 1rem;
  transition: all 0.3s ease;
}

.certificate-button:hover {
  background-color: var(--primary-color);
}

/* Estilos del botón "Bajar Info PDF" */
.pdf-button {
  background-color: var(--primary-color);
  border-radius: 25px;
  color: var(--light-color);
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  margin: 1rem;
  transition: all 0.3s ease;
}

.pdf-button:hover {
  background-color: var(--secondary-color);
}

/* Estilos para dark mode */
.dark-mode {
  --primary-color: #ffdf00;
  --secondary-color: #222222;
  --light-color: #e0e0e0;
  --dark-color: #f5f5f5;

  background: linear-gradient(to right, #222222, #444444);
  color: var(--light-color);
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode a,
.dark-mode .button,
.dark-mode .card-cus,
.dark-mode .card-header,
.dark-mode .certificate-button,
.dark-mode .pdf-button {
  color: var(--light-color);
}

.dark-mode .button:hover,
.dark-mode .certificate-button:hover,
.dark-mode .pdf-button:hover {
  background-color: var(--primary-color);
}

.dark-mode .cv-mat-container {
  background: linear-gradient(to right, #222222, #444444);
  color: var(--light-color);
}

.dark-mode .card-container {
  background: var(--dark-color);
}

.dark-mode .card-cus {
  background: var(--dark-color);
}

.dark-mode .card-header {
  background: var(--primary-color);
}

.dark-mode .education div,
.dark-mode .experience div,
.dark-mode .other div,
.dark-mode .contact-info div,
.dark-mode .skills div {
  color: var(--light-color);
}

.dark-mode hr {
  border-color: var(--light-color);
}
</style> 

