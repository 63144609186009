<template>


  <router-view :device="device"/>

</template>


<style >
 
</style>

<script>

 const breakpoints = {
        mobile: {until: 760},
        tablet: {from: 720, until: 1023},
        desktop: {from: 1024, until: 2408},
        widescreen: {from: 1216, until: 1407},
        fullhd: {from: 1408}
    };


  export default {
  name: 'App',
  components: {},
  methods: {
    onResize(){
      this.device.isMobile = window.innerWidth <= breakpoints.mobile.until;
      this.device.isTablet = window.innerWidth >= breakpoints.tablet.from;
      this.device.isTabletOnly = window.innerWidth >= breakpoints.tablet.from && window.innerWidth <= breakpoints.tablet.until;
      this.device.isDesktop = window.innerWidth >= breakpoints.desktop.from;
      this.device.isDesktopOnly = window.innerWidth >= breakpoints.desktop.from && window.innerWidth <= breakpoints.desktop.until;
      this.device.isWidescreen = window.innerWidth >= breakpoints.widescreen.from;
      this.device.isWidescreenOnly = window.innerWidth >= breakpoints.widescreen.from && window.innerWidth <= breakpoints.widescreen.until;
      this.device.isFullhd = window.innerWidth >= breakpoints.fullhd.from;
    },
  },
  created(){
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  mounted() {

  },
  data() {
    return {
        device: {
                  isMobile: false,
                  isTablet: false,
                  isTabletOnly: false,
                  isDesktop: false,
                  isDesktopOnly: false,
                  isWidescreen: false,
                  isWidescreenOnly: false,
                  isFullhd: false
              }
    };
  }
};
</script>



