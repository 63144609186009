<template>
  <header id="header">
    <video id="background-video" autoplay loop muted>
      <source src="@/assets/video1.mp4" type="video/mp4">
      Tu navegador no soporta el elemento de video.
    </video>
    <h3>Soluciones Naturales</h3>
    <div class="titles-container">
      <ul class="titles-list left">
        <li v-for="(title, index) in leftTitles" :key="index">{{ title }}</li>
      </ul>
      <transition :name="transitionName">
        <h1 v-if="showTitle" key="title" class="animated-title">{{ titles[currentIndex] }}</h1>
      </transition>
      <ul class="titles-list right">
        <li v-for="(title, index) in rightTitles" :key="index">{{ title }}</li>
      </ul>
    </div>
    <ButtonP @click="scrollToContact" label="WhatsApp" icon="pi pi-whatsapp" class="button-success ml-3" />
  </header>

    </template>
<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      titles: [
        'Software Personalizado',
        'Transición a la Nube',
        'Seguridad',
        'Optimizaciones',
        'Certificación en la Gestión',
        'Desarrollo Móvil',
      ],
      currentIndex: 0,
      showTitle: true,
      transitionName: 'fade',
          leftTitles: [],
      rightTitles: [],
      animationRunning: true, 
    };
  },
  methods: {

    scrollToContact() {
      const contactSection = document.getElementById('platforms');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
      changeTitle() {
      if (this.currentIndex < this.titles.length) {
        this.showTitle = !this.showTitle;
        if (this.showTitle) {
          if (this.currentIndex % 2 === 0) {
            this.leftTitles.push(this.titles[this.currentIndex]);
          } else {
            this.rightTitles.push(this.titles[this.currentIndex]);
          }
          this.currentIndex++;
        }
        setTimeout(this.changeTitle, 1500);
      }
    },
  },
  watch: {
    showTitle(newVal) {
      if (!newVal) {
        this.currentIndex = (this.currentIndex + 1) % this.titles.length;
        setTimeout(() => {
          this.showTitle = true;
        }, 500);
      }
    },
  },
  mounted() {
   this.changeTitle();  // Cambia el título cada 3 segundos
  },
};
</script>




<style scoped>

#background-video {
  position: absolute; /* Posición absoluta para el video */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Esto asegurará que el video cubra todo el espacio sin distorsionar su relación de aspecto */
  z-index: -1; /* Esto posicionará el video detrás del texto */
}


#header {
  position: relative;

  height: 100vh ; /* Altura del encabezado */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#header h3, #header p {
  color: white; /* Color de texto azul oscuro */
  background-color: rgba(255, 255, 255, 0.4); /* Fondo blanco semi-transparente para mejorar la legibilidad */
  padding: 10px;
  border-radius: 8px; /* Esquinas redondeadas */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.titles-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.titles-list {
  list-style-type: none;
  padding: 0;
}

.left, .animated-title, .right {
  color: white;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de;
}

.animated-title {
  font-size: 2rem;
}
</style>





