<template>
  <section id="platforms">
    <video id="background-video" autoplay loop muted playsinline>
      <source src="@/assets/videowhite.mp4" type="video/mp4">
      Tu navegador no soporta el elemento de video.
    </video>
    <div class="content-box">
      <h2>Contactanos</h2>
      <p>Nuestra ejecutiva de ventas te atendera.</p>
      <button  @click="$emit('contact-clicked')">
        <i class="fab fa-whatsapp" style="margin-right: 8px;"></i>
        Contactar
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PlatformsComponent',
   methods: {
    goto(){
         this.$router.push({name: 'contact'});
    },
    scrollToContact() {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
#platforms {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  border: 4px solid white;
  overflow: hidden; /* Esconder el overflow para que el video no se salga de la sección */
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegurar que el video cubra toda el área sin distorsionarse */
  z-index: -1; /* Posicionar el video detrás del contenido */
}

.content-box {
  position: relative;
  text-align: center;
  z-index: 1; /* Posicionar el contenido por encima del video */
}


/* Estilo del botón */
button {
  background-color: white;  /* Color de fondo blanco */
  color: #008CBA;  /* Color de texto azul */
  padding: 20px 40px;  /* Espaciado interno más grande */
  border: 2px solid #008CBA;  /* Borde azul */
  border-radius: 4px;  /* Esquinas redondeadas */
  cursor: pointer;  /* Cursor de mano al pasar por encima */
  font-size: 1.5em;  /* Tamaño de fuente más grande */
}

button:hover {
  background-color: #008CBA;  /* Color de fondo azul al pasar el ratón por encima */
  color: white;  /* Color de texto blanco al pasar el ratón por encima */
}
</style>
