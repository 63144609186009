<template>
  <section id="contact" data-aos="fade-in" class="d-flex jc-center ai-center" style="height: 100vh; width: 100%;">
    <div class="content-box m-3 shadow-4">
      <h2>Contactanos</h2>
      <form @submit.prevent="handleSubmit" class="fluid">
        <InputText v-model="companyName" placeholder="Nombre de la empresa" class="mb-3" />
        <InputText v-model="name" placeholder="Tu Nombre" class="mb-3" />
         <br />
        <InputText v-model="email" placeholder="Correo electrónico" class="mb-3" />
        <InputText v-model="phone" placeholder="Teléfono" class="mb-3" />
        <br />
        <InputText v-model="message" placeholder="Escribe tu mensaje aquí" rows="5" class="mb-3" />
        <!-- ... otros campos ... -->

        <h4>Indica los servicios de tu interés</h4>
         <!-- Selección de servicios -->
        <div v-for="(service, index) in services" :key="index" class="checkbox-group">
          <input type="checkbox" :value="service" v-model="selectedServices" :id="service">
          <label :for="service">{{ service }}</label>
        </div>

        <h4>Indica los Productos</h4>

        <div v-for="(product, index) in products" :key="index" class="checkbox-group">
          <input type="checkbox" :value="service" v-model="selectedProduct" :id="product">
          <label :for="product">{{ product }}</label>
        </div>
<br /><br />
        <div class="d-flex jc-between">
          <ButtonP @click="sendEmail" label="Correo Electrónico" icon="pi pi-envelope" class="button-success" />
          <ButtonP @click="openWhatsApp" label="WhatsApp" icon="pi pi-whatsapp" class="button-success ml-3" />
            <br />
              <br />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      services: ['Venta de Software a Medida', 'Migración a la Nube', 'Desarrollo de Aplicaciones Móviles'],
      products: ['Venta de Software a Medida', 'Migración a la Nube', 'Desarrollo de Aplicaciones Móviles'],
      selectedServices: [],
      companyName: '',
      email: '',
      phone: '',
      name: ''
    };
  },


  methods: {
    handleSubmit() {
      //const captchaResponse = grecaptcha.getResponse();
      //if (!captchaResponse) {
        //alert('Por favor, complete el CAPTCHA');
        return;
      //}
      // ... tu lógica para enviar la información de contacto y los servicios seleccionados
    },
  sendEmail() {
    const emailBody = `Nombre de la empresa: ${this.companyName}\nEmail: ${this.email}\nTeléfono: ${this.phone}\nMensaje: ${this.message}`;
    window.open(`mailto:info@tuempresa.com?subject=Solicitud de Contacto&body=${encodeURIComponent(emailBody)}`);
  },
  openWhatsApp() {
    const waMessage = `Hola, estoy interesado en los siguientes servicios: ${this.selectedServices.join(', ')}.\nMensaje: ${this.message}`;
    window.open(`https://wa.me/56999999999?text=${encodeURIComponent(waMessage)}`);
  }
},


  mounted() {
    // Asegúrate de cargar el script de reCAPTCHA en tu página
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    document.head.appendChild(script);
  }
};
</script>

<style type="text/css">
  
  .content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* ... otros estilos ... */
}


</style>
<!-- ... tus estilos ... -->
