<template>
  <div id="app">
    <HeaderComponent />
    <ServicesComponent />
    <ProductsComponent />
    <StoreAddComponent />
    <transition name="fade" mode="out-in">
      <PlatformsComponent v-if="!showContact" @contact-clicked="showContact = true" key="platforms"/>
      <ContactComponent v-else key="contact" data-aos="fade-in"/>
    </transition>
    <FooterComponent />
  </div>
</template>

<script>
  import '@/assets/style.css';
  import HeaderComponent from '@/components/HeaderComponent.vue';
  import ServicesComponent from '@/components/ServicesComponent.vue';
  import ProductsComponent from '@/components/ProductsComponent.vue';
  import PlatformsComponent from '@/components/PlatformsComponent.vue';
  import FooterComponent from '@/components/FooterComponent.vue';
  import ContactComponent from '@/components/ContactComponent.vue';
  import StoreAddComponent from '@/components/StoreAddComponent.vue';



export default {
  name: 'LandingPage',
  data() {
    return { showContact: false };
  },
   beforeMount(){
      window.scrollTo(0, 0);
      if(this.isCv()){
        this.goto('cv-mat');
      }
  },
  components: { 
    HeaderComponent,
    ServicesComponent,
    ProductsComponent,
    PlatformsComponent,
    FooterComponent,
    ContactComponent,
    StoreAddComponent
  },
   methods: {
     goto(route) {
      this.$router.push(route)
    },
    isCv() {
    return this.$route.query.cv === 'true';
    },
  },
};

</script>

<style>

.fade-enter-active, .fade-leave-active { transition: opacity 1s; }
.fade-enter, .fade-leave-to { opacity: 0; }

#app {
  position: relative;
}

/* Otros componentes */
HeaderComponent,
ServicesComponent,
ProductsComponent,
PlatformsComponent,
FooterComponent {
  position: relative;
  z-index: 1;
}
</style>


