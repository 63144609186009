import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'primevue/resources/themes/saga-blue/theme.css';    // Tema de PrimeVue
import 'primevue/resources/primevue.min.css';             // Estilos principales de PrimeVue
import 'primeicons/primeicons.css';                       // Estilos de PrimeIcons
import 'primeflex/primeflex.css';    

// Importación de las bibliotecas
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importas el CSS de AOS
import 'animate.css'; // Importas Animate.css


import Button from 'primevue/button';
import InputText from 'primevue/inputtext';


const app = createApp(App);

app.component('ButtonP', Button);
app.component('InputText', InputText);


app.use(store).use(router).mount('#app')

document.addEventListener('DOMContentLoaded', function () {
  AOS.init();
});
