export default {
  name: 'Matias Alemparte Ingeniero de Software',
  email: 'matias.alemparte@gmail.com',
  languages: 'Español(Nativo), inglés (avanzado: hablado y comprensión lectora)',
  address: 'Avenida Eastman 260r, Olmue Chile',
  experience: [
    {
      title:
        '(Actualmente trabajando) Desarrollo para Transportes Ventanas, Aplicación hibrida y web de apoyo a la gestión de la empresa en todos sus modelos.',
      company: 'Transportes Ventanas',
      description:
        'Desarrollo de aplicación híbrida y web de apoyo a la gestión de la empresa en todos sus modelos.',
      date: '',
    },
    {
      title:
        '(Actualmente trabajando) Desarrollo para ASEDUC, Conjunto de Aplicaciónes educacionales. Radios(Aconcagua y San Nicolas ) para comunidades Escolares.',
      company: 'ASEDUC',
      description:
        'Desarrollo de conjunto de aplicaciones educacionales. Radios (Aconcagua y San Nicolás) para comunidades escolares.',
      date: '',
    },
    {
      title:
        '(Actualmente trabajando) Desarrollo para Upsidedown.cl, Aplicación web de test de conocimiento, razonamiento lógico e ingreso de postulaciones a cargos.',
      company: 'Upsidedown.cl',
      description:
        'Desarrollo de aplicación web para test de conocimiento y razonamiento lógico, así como también para ingreso de postulaciones a cargos en la empresa.',
      duration: '2021 - Presente',
    },
    {
      title:
        'Desarrollo para Transportes Ventanas, Aplicación hibrida y web de apoyo a la gestión de la empresa en todos sus modelos.',
      company: 'Transportes Ventanas',
      description:
        'Desarrollo de aplicación híbrida y web para apoyo a la gestión de la empresa en todos sus modelos.',
      duration: '2020 - Presente',
    },
    {
      title:
        'Desarrollo para ASEDUC, Conjunto de Aplicaciones educacionales. Radios(Aconcagua y San Nicolas ) para comunidades Escolares.',
      company: 'ASEDUC',
      description:
        'Desarrollo de conjunto de aplicaciones educacionales, así como también de radios para comunidades escolares.',
      duration: '2020 - Presente',
    },
    {
      title:
        'Desarrollo para www.cityex.cl, Aplicación móvil hibrida y web de gestión.',
      company: 'www.cityex.cl',
      description:
        'Desarrollo de aplicación móvil híbrida y web de gestión para la empresa.',
      duration: '2020',
    },
    {
      title:
        'Desarrollo para olha.cl, Aplicación móvil hibrida y web de gestión. ERP para Pymes.',
      company: 'olha.cl',
      description:
        'Desarrollo de aplicación móvil híbrida y web de gestión ERP para Pymes, con herramientas contables, ecommerce, webpay, emisión de facturas electrónicas y registro de compras y ventas en SII.',
      duration: '2020',
    },
    {
      title: 'Desarrollo para www.miaula.cl, plataforma deplataforma de gestión de colegios.',
company: 'www.miaula.cl',
description: 'Desarrollo de plataforma de gestión de colegios.',
duration: '2018'
},
{
title: 'Desarrollo para www.viverolimiache.cl, plataforma de gestión de pedidos, productos, cotizaciones.',
company: 'www.viverolimiache.cl',
description: 'Desarrollo de plataforma de gestión de pedidos, productos y cotizaciones.',
duration: '2018'
},
{
title: 'Desarrollo de grupo de aplicaciones móviles híbridas para Android e IOS. HikingOnMaui, HikingOnOhau, HikingBigIsland y HikingKauai. Aplicaciones para turistas, donde se entrega información importante para realizar caminatas en las islas de Hawaii.',
company: '',
description: 'Desarrollo de grupo de aplicaciones móviles híbridas para Android e IOS, con información importante para realizar caminatas en las islas de Hawaii.',
duration: '2015 - 2017'
},
{
title: '2 años en Zeke.cl Desarrollo de sistemas para instituciones gubernamentales. Desarrollo de sistemas transaccionales.',
company: 'Zeke.cl',
description: 'Desarrollo de sistemas para instituciones gubernamentales, con enfoque en sistemas transaccionales.',
duration: '2012 - 2014'
}
],
skills: [
'Java Spring Hibernate', 'Jpa', 'J2EE', 'Win Forms', 'C#', 'C', 'C++', 'PHP slim', 'Atlas ORM', 'HTML', 'JavaScript', 'VueJS', 'SQL Server', 'MySQL', 'PostgreSQL', 'SVN', 'GIT', 'Linux', 'Photoshop', 'Gimp', 'Gank', 'MS Office', 'Open Office'
],
programmingLanguages: [
'Java Spring Hibernate',
'Jpa',
'J2EE',
'Win Forms',
'C#',
'C',
'C++',
'PHP slim',
'Atlas ORM',
'HTML',
'JavaScript',
'VueJS'
],
databases: [
'SQL Server',
'MySQL',
'PostgreSQL'
],
versionControl: [
'SVN',
'GIT'
],
cloudConfiguration: [
'Linux'
],
otherSoftware: [
'Photoshop',
'Gimp',
'Gank',
'MS Office',
'Open Office'
],
additionalSkills: [
'Gestión de proyectos',
'Metodologías Ágiles',
'Scrum',
'Kanban'
],
degree: 'Ingeniero Informático',
university: 'Universidad Católica de Valparaíso, Chile',
graduationDate: 'Titulado en 2010',
hobbies: 'Pasión por deportes (surf – tenis – trotar), fotografía y agricultura orgánica.'
}
